* {
  font-family: 'Pretendard Variable', '-apple-system', 'BlinkMacSystemFont',
    'Apple SD Gothic Neo', 'sans-serif';
  -webkit-font-smoothing: 'antialiased';
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  word-break: keep-all;
  scrollbar-gutter: 'auto';
}

html,
body {
  min-height: 100%;
  -webkit-text-size-adjust: none; /* 크롬, 사파리, 오페라 신버전 */
  -ms-text-size-adjust: none; /* IE */
  -moz-text-size-adjust: none; /* 파이어폭스 */
  -o-text-size-adjust: none; /* 오페라 구버전 */
}

mdl-layout {
  min-height: 100%;
}

a {
  text-decoration: none !important;
}

@keyframes LogoSlideKeyframe {
  0% {
    transform: translate3d(0, 0, 0);
  }
  100% {
    transform: translate3d(-1500px, 0, 0);
  }
}

@keyframes fadeInRight {
  from {
    opacity: 0;
    transform: translateX(100px);
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
  }
}

@keyframes pulse {
  25% {
    transform: scale(0.8);
  }
  75% {
    transform: scale(1.2);
  }
}

.fadeInAnimation {
  animation: fadeIn 0.3s ease-in-out;
}

.fadeInUpAnimation {
  animation: fadeInUp 0.5s ease-in-out;
}

/* Scroll Bar */
*::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

*::-webkit-scrollbar-track {
  background-color: transparent;
  border-radius: 10px;
}

*::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 10px;
}

.hide-scrollbar {
  overflow: scroll;
  -ms-overflow-style: none;
  /* for Internet Explorer, Edge */
  scrollbar-width: none;
  /* for Firefox */
}

.hide-scrollbar::-webkit-scrollbar {
  display: none;
  /* for Chrome, Safari, and Opera */
}

@media only screen and (max-width: 576px) {
  ::-webkit-scrollbar {
    display: none;
    overflow: scroll;
    -ms-overflow-style: none;
    /* for Internet Explorer, Edge */
    scrollbar-width: none;
    /* for Firefox */
  }
}

.pointer-cursor {
  cursor: pointer;
}

.shake-animation {
  -webkit-animation: shake-bottom 1s cubic-bezier(0.455, 0.03, 0.515, 0.955)
    0.5s both;
  animation: shake-bottom 1s cubic-bezier(0.455, 0.03, 0.515, 0.955) 0.5s both;
}

@-webkit-keyframes shake-bottom {
  0%,
  100% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transform-origin: 50% 100%;
    transform-origin: 50% 100%;
  }
  10% {
    -webkit-transform: rotate(2deg);
    transform: rotate(2deg);
  }
  20%,
  40%,
  60% {
    -webkit-transform: rotate(-4deg);
    transform: rotate(-4deg);
  }
  30%,
  50%,
  70% {
    -webkit-transform: rotate(4deg);
    transform: rotate(4deg);
  }
  80% {
    -webkit-transform: rotate(-2deg);
    transform: rotate(-2deg);
  }
  90% {
    -webkit-transform: rotate(2deg);
    transform: rotate(2deg);
  }
}
@keyframes shake-bottom {
  0%,
  100% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transform-origin: 50% 100%;
    transform-origin: 50% 100%;
  }
  10% {
    -webkit-transform: rotate(2deg);
    transform: rotate(2deg);
  }
  20%,
  40%,
  60% {
    -webkit-transform: rotate(-4deg);
    transform: rotate(-4deg);
  }
  30%,
  50%,
  70% {
    -webkit-transform: rotate(4deg);
    transform: rotate(4deg);
  }
  80% {
    -webkit-transform: rotate(-2deg);
    transform: rotate(-2deg);
  }
  90% {
    -webkit-transform: rotate(2deg);
    transform: rotate(2deg);
  }
}
