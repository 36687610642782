@keyframes fadeInDown {
  0% {
    opacity: 0;
    transform: translateY(-50%);
  }
  to {
    opacity: 2;
  }
}

.updated {
  position: relative;
  animation: fadeInDown 1s;
}
