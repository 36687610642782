.body {
  min-height: calc(100vh - 130px);
  padding: 48px 0px 90px;
  margin: auto;
}

@media (min-width: 1280px) {
  .body {
    max-width: 1280px !important;
  }
}

@media (max-width: 1280px) {
  .body {
    padding: 24px 16px 80px;
    box-sizing: border-box;
  }
}
